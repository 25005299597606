import { render, staticRenderFns } from "./placeholderScreen.vue?vue&type=template&id=f081f9c0&scoped=true"
import script from "./placeholderScreen.vue?vue&type=script&lang=js"
export * from "./placeholderScreen.vue?vue&type=script&lang=js"
import style0 from "./placeholderScreen.vue?vue&type=style&index=0&id=f081f9c0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f081f9c0",
  null
  
)

export default component.exports