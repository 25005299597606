<template>
    <div class="schoolSpace">
        <div class="schName flex-align-center">
            {{ $route.query.schoolName }}学校空间
        </div>
        <div class="spceType">
            <div class="location_L">
                <div class="live_lesson mt_style">
                    <div class="live_lesson_headline headline_bg">
                        <div class="font_style">直播课学习情况</div>
                        <img src="../../assets/Screen/sanj.png">
                    </div>
                    <div class="live_lesson_graphic" id="live_lesson_graphic" v-if="placeholder_show1"></div>
                    <PlaceholderScreen class="live_lesson_graphic" v-else></PlaceholderScreen>
                </div>
                <div class="record mt_style">
                    <div class="record_headline headline_bg">
                        <div class="font_style">录播课学习情况</div>
                        <img src="../../assets/Screen/sanj.png">
                    </div>
                    <div class="record_graphic" id="record_graphic" v-if="placeholder_show2"></div>
                    <PlaceholderScreen class="record_graphic" v-else></PlaceholderScreen>
                </div>
                <div class="date_study">
                    <div class="date_study_headline headline_bg">
                        <div class="font_style">各项目参训率</div>
                        <!-- 各时段学习情况 -->
                        <img src="../../assets/Screen/sanj.png">
                    </div>
                    <div class="date_study_graphic" id="date_study_graphic" v-if="placeholder_show3"></div>
                    <PlaceholderScreen class="date_study_graphic" v-else></PlaceholderScreen>
                </div>
            </div>
            <div class="location_C">
                <div class="classification flex-column-between">
                    <div class="flex-align-between">
                        <div class="classification_bg classification_img1 flex-column-center">
                            <div class="title">参与总人数</div>
                            <div class="type_num">
                                <div class="num">{{ participationValue || 0 }}</div>
                                <div class="unit">人</div>
                            </div>
                        </div>
                        <div class="classification_bg classification_img2 flex-column-center">
                            <div class="title">项目总数量</div>
                            <div class="type_num">
                                <div class="num">{{ projectValue || 0 }}</div>
                                <div class="unit">个</div>
                            </div>
                        </div>
                        <div class="classification_bg classification_img3 flex-column-center">
                            <div class="title">参训率</div>
                            <div class="type_num">
                                <div class="num">{{ cultivateValue || 0 }}</div>
                                <div class="unit">%</div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-align-between">
                        <div class="classification_bg classification_img4 flex-column-center">
                            <div class="title">直播学习总时长</div>
                            <div class="type_num">
                                <div class="num">{{ livestudyValue || 0 }}</div>
                                <div class="unit">min</div>
                            </div>
                        </div>
                        <div class="classification_bg classification_img5 flex-column-center">
                            <div class="title">录播学习总时长</div>
                            <div class="type_num">
                                <div class="num">{{ recordstudyValue || 0 }}</div>
                                <div class="unit">min</div>
                            </div>
                        </div>
                        <div class="classification_bg classification_img6 flex-column-center">
                            <div class="title">作业成果</div>
                            <div class="type_num">
                                <div class="num">{{ jobresultValue || 0 }}</div>
                                <div class="unit">个</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="totality mt_style">
                    <div class="totality_headline headline_bg">
                        <div class="font_style">项目总体情况</div>
                        <img src="../../assets/Screen/sanj.png">
                    </div>
                    <div class="totality_graphic" id="totality_graphic" v-if="placeholder_show4"></div>
                    <PlaceholderScreen class="totality_graphic" v-else></PlaceholderScreen>
                </div>
                <div class="word_cloud" id="word_cloud" v-if="placeholder_show5"></div>
                <PlaceholderScreen class="word_cloud" v-else></PlaceholderScreen>

            </div>
            <div class="location_R">
                <div class="submit_job mt_style">
                    <div class="submit_job_headline headline_bg">
                        <div class="font_style">提交作业人数情况</div>
                        <img src="../../assets/Screen/sanj.png">
                    </div>
                    <div class="submit_job_graphic" id="submit_job_graphic" v-if="placeholder_show6"></div>
                    <PlaceholderScreen class="submit_job_graphic" v-else></PlaceholderScreen>
                </div>
                <div class="project">
                    <div class="project_headline headline_bg">
                        <div class="font_style">各时段学习情况</div>
                        <img src="../../assets/Screen/sanj.png">
                    </div>
                    <div class="project_graphic" id="project_graphic" v-if="placeholder_show7"></div>
                    <PlaceholderScreen class="project_graphic" v-else></PlaceholderScreen>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PlaceholderScreen from '../../common/placeholderBox/placeholderScreen.vue';
export default {
    //import引入组件才能使用

    components: {
        PlaceholderScreen
    },
    props: {},
    data() {
        return {
            //-----
            // 参与总人数
            participationValue: '0',
            // 项目总数量
            projectValue: '0',
            // 参训率
            cultivateValue: '0',
            // 直播学习总时长
            livestudyValue: '0',
            // 录播学习总时长
            recordstudyValue: '0',
            // 作业成果
            jobresultValue: '0',
            //-----
            //图形背景色
            graphics_bg: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0.5,
                color: 'rgba(10, 46, 105,0.1)'
            }, {
                offset: 1,
                color: 'rgba(10, 46, 105,1)'
            }]),
            sampleSize: ([...arr], n = 1) => {
                let m = arr.length;
                while (m) {
                    const i = Math.floor(Math.random() * m--);
                    [arr[m], arr[i]] = [arr[i], arr[m]];
                }
                return arr.slice(0, n);
            },
            // 空数据处理
            placeholder_show1: true,
            placeholder_show2: true,
            placeholder_show3: true,
            placeholder_show4: true,
            placeholder_show5: true,
            placeholder_show6: true,
            placeholder_show7: true,
        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        //学校空间-项目学习数据
        async getInfoList() {
            let data = {
                schoolId: this.$route.query.schoolId
            };
            let resData = await this.$Api.dataScreen.getschoolTitle(data);
            this.participationValue = resData.data.userCount || 0;
            this.projectValue = resData.data.projectCount || 0;
            this.livestudyValue = ((resData.data.live || 0) / 60).toFixed(2);
            this.recordstudyValue = ((resData.data.record / 60 || 0)).toFixed(2);
            this.jobresultValue = resData.data.homework || 0;
        },
        //学校空间-项目总体情况
        async getprojectUserCount() {
            let data = {
                schoolId: this.$route.query.schoolId,
            };
            let value = []
            let resData = await this.$Api.dataScreen.getprojectUserCount(data);
            if (Object.keys(resData.data).length == 0) return this.placeholder_show4 = false;
            for (let i = 0; i < resData.data.length; i++) {
                value.push({
                    value: resData.data[i].count,
                    name: resData.data[i].projectName,
                    projectId: resData.data[i].projectId,
                })
            };
            this.totality_graphics(value)
        },
        //学校空间-项目热门名字
        async getprojectUserName(projectId) {
            let data = {
                schoolId: this.$route.query.schoolId,
                page: 1,
                limit: 120,
                projectId: projectId
            };
            let resData = await this.$Api.dataScreen.getprojectUserName(data);
            if (Object.keys(resData.data).length == 0) return this.placeholder_show5 = false;
            let datas = resData.data.names;
            datas.shift();
            let value = [];
            for (let i = 0; i < datas.length; i++) {
                value.push({
                    name: datas[i],
                    value: Math.floor(Math.random() * 200) + 1
                })
            }
            this.word_cloud_graphics(value)
        },
        //学校空间-项目学习数据(直播、录播)
        async getprojectStudyInfo() {
            let data = {
                schoolId: this.$route.query.schoolId,
            };
            let sch_liveValue = [];
            let city_liveValue = [];
            let area_liveValue = [];
            let live_xAxisdata = [];

            let sch_recordValue = [];
            let city_recordValue = [];
            let area_recordValue = [];
            let record_xAxisdata2 = [];
            let resData = await this.$Api.dataScreen.getprojectStudyInfo(data);
            if (Object.keys(resData.data).length == 0) {
                this.placeholder_show1 = false;
                this.placeholder_show2 = false;
                return;
            };
            let livedata = resData.data.live;
            let recorddata = resData.data.record;
            for (let i = 0; i < livedata.length; i++) {
                sch_liveValue.push((livedata[i].schoolValue / 60).toFixed(2));
                city_liveValue.push((livedata[i].cityValue / 60).toFixed(2));
                area_liveValue.push((livedata[i].areaValue / 60).toFixed(2));
                live_xAxisdata.push(livedata[i].projectName)
            };
            for (let i = 0; i < recorddata.length; i++) {
                sch_recordValue.push((recorddata[i].schoolValue / 60).toFixed(2));
                city_recordValue.push((recorddata[i].cityValue / 60).toFixed(2));
                area_recordValue.push((recorddata[i].areaValue / 60).toFixed(2));
                record_xAxisdata2.push(recorddata[i].projectName)
            };
            this.live_lesson_graphics(live_xAxisdata, sch_liveValue, city_liveValue, area_liveValue);
            this.record_graphics(record_xAxisdata2, sch_recordValue, city_recordValue, area_recordValue)

        },
        // 学校空间-各时段作业人数统计
        async gettimeQuest() {
            let data = {
                schoolId: this.$route.query.schoolId
            };
            let xAxisData = [];
            let seriesData = [];
            let resData = await this.$Api.dataScreen.gettimeQuest(data);
            if (Object.keys(resData.data).length == 0) return this.placeholder_show6 = false;
            let sum = this.summation(resData.data);
            console.log(' resData.data', resData.data);
            for (let i = 0; i < resData.data.length; i++) {
                xAxisData.push(resData.data[i].time)
                let dispose = (sum != 0 ? resData.data[i].count / sum : resData.data[i].count).toFixed(2) * 100;
                seriesData.push(Number(dispose.toString().match(/^\d+(?:\.\d{0,2})?/)))
            }
            this.submit_job_graphics(xAxisData, seriesData)
        },
        // 学校空间-各时段学习情况
        async gettimeStudy() {
            let data = {
                schoolId: this.$route.query.schoolId
            };
            let xAxisData = [];
            let seriesData = [];
            let resData = await this.$Api.dataScreen.gettimeStudy(data);
            if (Object.keys(resData.data).length == 0) return this.placeholder_show7 = false;
            let sum = this.summation(resData.data);
            console.log('sum',sum);
            for (let i = 0; i < resData.data.length; i++) {
                xAxisData.push(resData.data[i].time);
                let dispose = (sum != 0 ? resData.data[i].count / sum : resData.data[i].count).toFixed(2) * 100;
                seriesData.push(Number(dispose.toString().match(/^\d+(?:\.\d{0,2})?/)))
            }
            this.date_study_graphics(xAxisData, seriesData)
        },
        // 学校空间-参训率情况
        async getparticipationRate() {
            let data = {
                schoolId: this.$route.query.schoolId
            };
            let xAxisData = [];
            let seriesData = [];
            let basicsData = [];
            let resData = await this.$Api.dataScreen.getparticipationRate(data);
            if (Object.keys(resData.data).length == 0) return this.placeholder_show3 = false;
            for (let i = 0; i < resData.data.length; i++) {
                xAxisData.push(resData.data[i].projectName);
                let judgeValue;
                if (resData.data[i].count == 0 || resData.data[i].count == null) {
                    judgeValue = 0;
                } else {
                    judgeValue = (resData.data[i].result / resData.data[i].count).toFixed(2) * 100
                }
                seriesData.push(judgeValue)
                basicsData.push(100)
            }
            this.cultivateValue = this.calculateAverage(seriesData);
            this.project_graphics(xAxisData, seriesData, basicsData);
            // console.log((0).toFixed(2));
            // console.log(null/60);
        },
        //计算平均值
        calculateAverage(arr) {
            let sum = 0;
            for (let i = 0; i < arr.length; i++) {
                sum += arr[i];
            }
            return (sum / arr.length).toFixed();
        },
        //计算求和
        summation(arr) {
            let sum = 0;
            arr.forEach(function (item) {
                sum += item.count;
            });
            return sum;
        },
        // 直播课学习情况*1
        live_lesson_graphics(live_xAxisdata, sch_liveValue, city_liveValue, area_liveValue) {
            this.$echarts.init(document.getElementById("live_lesson_graphic")).dispose();
            let that = this;
            let myChart = this.$echarts.init(
                document.getElementById("live_lesson_graphic")
            );
            myChart.setOption({
                backgroundColor: that.graphics_bg,
                color: [
                    new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, //变化度
                        //三种由深及浅的颜色
                        [{
                            offset: 0,
                            color: 'rgba(250, 218, 76,1)'
                        }, {
                            offset: 1,
                            color: 'rgba(61, 116, 230,1)'
                        }]),
                    new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, //变化度
                        //三种由深及浅的颜色
                        [{
                            offset: 0,
                            color: 'rgba(250, 218, 76,1)'
                        }, {
                            offset: 1,
                            color: 'rgba(112, 254, 76, 1)'
                        }]),
                    new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, //变化度
                        //三种由深及浅的颜色
                        [{
                            offset: 0,
                            color: 'rgba(250, 17, 138,1)'
                        }, {
                            offset: 1,
                            color: 'rgba(147, 18, 250, 1)'
                        }]),

                ],
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    icon: 'circle',
                    bottom: '1%',
                    textStyle: { //图例文字的样式
                        color: '#fff',
                        fontSize: 11
                    }
                },
                grid: {
                    top: '18%',
                    left: '5%',
                    right: '11%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLine: {
                        lineStyle: {
                            color: "rgba(117, 168, 202, 0.4)"
                        }
                    },
                    axisLabel: {
                        color: '#fff',
                        interval: 0,
                        textStyle: {
                            color: "#fff",
                            fontSize: 11,

                        },
                        formatter:function (value) {
                            if (value.length > 6) {
                                return `${value.slice(0,6)}...`;
                            }
                            return value;
                        }
                    },
                    data: live_xAxisdata
                },
                yAxis: {
                    type: 'value',
                    name: '单位：分钟',
                    // name: '单位：秒',
                    inverse: false,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(117, 168, 202, 0.3)',
                            type: 'dashed'
                        }
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: "#fff"
                        }
                    },
                    axisLabel: {
                        formatter: "{value}",
                        textStyle: {
                            color: "#fff",
                            fontSize: 11
                        }
                    },
                    nameTextStyle: {
                        color: "#fff",
                        padding: [0, 0, 0, 15],
                        fontSize: 11
                    },
                    axisTick: {
                        show: false
                    }
                },
                series: [
                    {
                        name: '学校',
                        type: "line",
                        symbol: 'circle', //设定为实心点
                        // showAllSymbol: true,
                        symbolSize: 5,
                        // smooth: true,
                        itemStyle: {
                            normal: {
                                lineStyle: { //线的颜色
                                    color: new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, //变化度
                                        //三种由深及浅的颜色
                                        [{
                                            offset: 0,
                                            color: 'rgba(250, 218, 76,1)'
                                        }, {
                                            offset: 1,
                                            color: 'rgba(61, 116, 230,1)'
                                        }]),
                                    width: 3
                                },
                                areaStyle: {
                                    type: 'default',
                                    //渐变色实现
                                    color: new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, //变化度
                                        //三种由深及浅的颜色
                                        [{
                                            offset: 0,
                                            color: 'rgba(250, 218, 76,0.2)'
                                        }, {
                                            offset: 1,
                                            color: 'rgba(61, 116, 230,0.2)'
                                        }])
                                },
                            }
                        },
                        data: sch_liveValue
                    },
                    {
                        name: '旗县',
                        type: "line",
                        symbol: 'circle', //设定为实心点
                        // showAllSymbol: true,
                        symbolSize: 5,
                        // smooth: true,
                        itemStyle: {
                            normal: {
                                lineStyle: { //线的颜色
                                    color: new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, //变化度
                                        //三种由深及浅的颜色

                                        [{
                                            offset: 0,
                                            color: 'rgba(250, 218, 76,1)'
                                        }, {
                                            offset: 1,
                                            color: 'rgba(112, 254, 76, 1)'
                                        }]),
                                    width: 3
                                },
                                areaStyle: {
                                    type: 'default',
                                    //渐变色实现
                                    color: new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, //变化度
                                        //三种由深及浅的颜色
                                        [{
                                            offset: 0,
                                            color: 'rgba(250, 218, 76,0.2)'
                                        }, {
                                            offset: 1,
                                            color: 'rgba(112, 254, 76, 0.2)'
                                        }])
                                },
                            }
                        },
                        data: area_liveValue
                    },
                    {
                        name: '盟市',
                        type: "line",
                        symbol: 'circle', //设定为实心点
                        // showAllSymbol: true,
                        symbolSize: 5,
                        // smooth: true,
                        itemStyle: {
                            normal: {
                                lineStyle: { //线的颜色
                                    color: new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, //变化度
                                        //三种由深及浅的颜色
                                        [{
                                            offset: 0,
                                            color: 'rgba(250, 17, 138,1)'
                                        }, {
                                            offset: 1,
                                            color: 'rgba(147, 18, 250, 1)'
                                        }]),
                                    width: 3
                                },
                                areaStyle: {
                                    type: 'default',
                                    //渐变色实现
                                    color: new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, //变化度
                                        //三种由深及浅的颜色
                                        [{
                                            offset: 0,
                                            color: 'rgba(250, 17, 138,0.2)'
                                        }, {
                                            offset: 1,
                                            color: 'rgba(147, 18, 250, 0.2)'
                                        }])
                                },
                            }
                        },
                        data: city_liveValue
                    },

                ]
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //-----
        // 录播课学习情况*2
        record_graphics(record_xAxisdata2, sch_recordValue, city_recordValue, area_recordValue) {
            this.$echarts.init(document.getElementById("record_graphic")).dispose();
            let that = this;
            let myChart = this.$echarts.init(
                document.getElementById("record_graphic")
            );
            myChart.setOption({
                backgroundColor: that.graphics_bg,
                dataZoom: [
                    {
                        type: 'slider',
                        show: false,
                        start: 0,
                        end: 60,
                        height: '4',
                        bottom: '0px',
                        zoomLock: true,
                        fillerColor: "#04A6FB", // 滚动条颜色
                        borderColor: "rgba(0,0,0, 0)",
                        backgroundColor: "#4e5d6a",
                        yAxisIndex: 0,     //关联多个y轴
                    },
                    {
                        type: "inside", // 支持内部鼠标滚动平移
                        start: 0,
                        end: 60,
                        yAxisIndex: 0,
                        zoomLock: true,
                        // zoomOnMouseWheel: true, // 关闭滚轮缩放
                        // moveOnMouseWheel: true, // 开启滚轮平移
                        moveOnMouseMove: true // 鼠标移动能触发数据窗口平移
                    }
                ],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
                    },
                },
                legend: {
                    show: true,
                    icon: 'circle',
                    top: 3,
                    left: 10,
                    itemGap: 10,
                    textStyle: {
                        color: '#fff',
                        fontSize: 11
                    },
                },
                grid: {
                    top: '14%',
                    left: '2%',
                    right: '5%',
                    bottom: '2%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            show: false,
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: false,
                        },

                    },
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}',
                            color: 'rgba(95, 187, 235, 1)',
                            textStyle: {
                                fontSize: 11,
                                color: '#fff'
                            },
                        },
                        axisTick: {
                            show: true,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#394458',
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: 'red',
                            },
                        },

                    },

                ],
                yAxis: [
                    {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true,
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgba(117, 168, 202, 0.3)',
                                type: 'dashed'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#394458',
                            },
                        },
                        axisLabel: {
                            interval: 0,
                            // margin: 10,
                            color: '#05D5FF',
                            textStyle: {
                                fontSize: 11,
                                color: '#fff'
                            },
                            formatter:function (value) {
                            if (value.length > 6) {
                                return `${value.slice(0,6)}...`;
                            }
                            return value;
                        }
                        },
                        data: record_xAxisdata2
                    },
                ],
                series: [

                    {
                        name: '学校',
                        type: 'bar',
                        barWidth: '7',
                        xAxisIndex: 1,
                        itemStyle: {
                            normal: {
                                color: 'rgba(8, 173, 237,1)',
                            },
                        },
                        data: sch_recordValue
                    },
                    {
                        name: '旗县',
                        type: 'bar',
                        barWidth: '7',
                        xAxisIndex: 1,
                        itemStyle: {
                            normal: {
                                color: 'rgba(254, 182, 77,1)',
                            },
                        },
                        data: area_recordValue
                    },
                    {
                        name: '盟市',
                        type: 'bar',
                        barWidth: '7',
                        xAxisIndex: 1,
                        itemStyle: {
                            normal: {
                                color: 'rgba(251, 110, 108, 1)',
                            },
                        },
                        data: city_recordValue
                    },
                ],
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //-----
        // 各时段学习情况*3
        date_study_graphics(xAxisData, seriesData) {
            this.$echarts.init(document.getElementById("project_graphic")).dispose();
            let that = this;
            let myChart = this.$echarts.init(
                document.getElementById("project_graphic")
            );
            myChart.setOption({
                backgroundColor: that.graphics_bg,
                tooltip: {
                    show: true,
                    formatter: "{a} <br/> 时间：{b}  <br/> 百分比：{c}%",
                    trigger: "axis", //axis , item
                    borderWidth: 1,
                    borderRadius: 0,
                    textStyle: {
                        color: "#fff",
                        align: "left"
                    }
                },
                grid: {
                    right: "5%",
                    top: "10%",
                    left: "3%",
                    bottom: "5%",
                    containLabel: true
                },
                xAxis: {
                    type: "category",
                    boundaryGap: true,
                    // data: xAxisData,
                    data: ['0-2', '2-4', '4-6', '6-8', '8-10', '10-12', '12-14', '14-16', '16-18', '18-20','20-22', '22-24'],
                    axisLabel: {
                        //坐标轴刻度标签的相关设置。
                        interval: 0, //设置为 1，表示『隔一个标签显示一个标签』
                        //	margin:15,
                        textStyle: {
                            // color: "#A8B1AF",
                            color: "#fff",
                            fontStyle: "normal",
                            fontSize: 11
                        }
                    },
                    axisTick: {
                        //坐标轴刻度相关设置。
                        show: false,
                    },
                    axisLine: {
                        //坐标轴轴线相关设置
                        lineStyle: {
                            color: "#3C9AEA"
                        }
                    },
                    splitLine: {
                        //坐标轴在 grid 区域中的分隔线。
                        show: false,
                    }
                },
                yAxis: [
                    {

                        type: "value",
                        // name: '单位：百分比',
                        splitNumber: 3,
                        inverse: false,
                        max: 100,
                        axisLabel: {
                            show: false,
                            textStyle: {
                                // color: "#A8B1AF",
                                color: '#fff',
                                fontStyle: "normal",
                                fontSize: 11
                            }
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(60, 154, 234, 0.2)"
                            }
                        }
                    }
                ],
                series: [
                    {
                        name: '学习情况',
                        type: "pictorialBar",
                        barWidth: "150%",
                        barCategoryGap: "12%",
                        stack: "数量",
                        label: {
                            normal: {
                                show: true,
                                position: "top",
                                formatter: '{c}%',
                                textStyle: {
                                    color: "#FFFFFF",
                                    fontSize: 11
                                }
                            }
                        },
                        itemStyle: {
                            color: new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, //变化度
                                //三种由深及浅的颜色
                                [{
                                    offset: 0,
                                    color: 'rgba(132, 172, 195,0.8)'
                                }, {
                                    offset: 1,
                                    color: 'rgba(34, 86, 195,1)'
                                }]),
                        },
                        symbol:
                            "path://M12.000,-0.000 C12.000,-0.000 16.074,60.121 22.731,60.121 C26.173,60.121 -3.234,60.121 0.511,60.121 C7.072,60.121 12.000,-0.000 12.000,-0.000 Z",
                        data: seriesData
                    }],
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //-----
        // 项目总体情况*5
        totality_graphics(data) {
            this.$echarts.init(document.getElementById("totality_graphic")).dispose();
            let that = this;
            let myChart = this.$echarts.init(
                document.getElementById("totality_graphic")
            );
            var colorList = ['#3ff7fd', '#44e8fb', '#4adaf9', '#4fcbf7', '#55bdf5', '#5aaef3']
            myChart.setOption({
                tooltip: {
                    show: false,
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c}',
                },
                // calculable: true,
                color: colorList,
                series: [
                    {
                        type: 'funnel',
                        top: '4%',
                        width: '64%',
                        gap: 1.5, //设置缝隙大小

                        label: {
                            show: true,
                            color: '#dce2e9',
                            fontSize: 11,
                            formatter: "《 {b} 》\n 数量：{c}人",
                        },
                        labelLine: {
                            show: true,
                            normal: {
                                length: 45,
                                position: 'right',
                                lineStyle: {
                                    width: 1,
                                    type: 'solid',
                                },
                            },
                        },
                        itemStyle: {
                            normal: {
                                borderWidth: 0,
                                opacity: 1
                            },
                        },
                        emphasis: {
                            label: {
                                position: 'inside',
                            },
                        },
                        data: data
                    },
                ],
            });
            myChart.on('click', (params) => {
                that.getprojectUserName(params.data.projectId)
            })
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //-----
        // 词云*6
        word_cloud_graphics(data) {
            this.$echarts.init(document.getElementById("word_cloud")).dispose();
            let that = this;
            let myChart = this.$echarts.init(
                document.getElementById("word_cloud")
            );
            myChart.setOption({
                "series": [{
                    "name": "排行",
                    "type": "wordCloud",
                    "gridSize": 15,
                    "sizeRange": [14, 32],//最小文字——最大文字
                    "rotationRange": [0, 0],
                    "shape": "circle",
                    "drawOutOfBound": true,
                    "autoSize": {
                        "enable": true,
                        "minSize": 6
                    },
                    "textStyle": {
                        "normal": {
                            "color": function () {
                                return 'rgb(' + [
                                    Math.round(Math.random() * 200 + 55),
                                    Math.round(Math.random() * 200 + 55),
                                    Math.round(Math.random() * 200 + 55)
                                ].join(',') + ')';
                            }
                        },
                    },
                    "data": that.sampleSize(data, 10)
                }]
            });
            setInterval(() => {
                myChart.setOption({
                    series: [{
                        data: that.sampleSize(data, 10)
                    }]
                });
            }, 10000);
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //-----
        // 提交作业人数情况*7
        submit_job_graphics(xAxisData, seriesData) {
            this.$echarts.init(document.getElementById("submit_job_graphic")).dispose();
            let that = this;
            let legendData = ["提交作业情况"];
            let myChart = this.$echarts.init(
                document.getElementById("submit_job_graphic")
            );
            myChart.setOption({
                backgroundColor: that.graphics_bg,
                tooltip: {
                    trigger: 'axis',
                    formatter: "{a} <br/>时间：{b} <br/>百分比: {c}%",
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    top: '10%',
                    left: '5%',
                    right: '2%',
                    bottom: '6%',
                    containLabel: true
                },
                xAxis: {
                    type: "category",
                    // data: xAxisData,
                    data: ['0-2', '2-4', '4-6', '6-8', '8-10', '10-12', '12-14', '14-16', '16-18', '18-20','20-22', '22-24'],
                    axisLine: {
                        lineStyle: {
                            color: "rgba(117, 168, 202, 0.4)"
                        },
                        show: true
                    },
                    axisTick: {
                        show: false
                    },

                    axisLabel: {
                        color: '#fff',
                        fontSize: 11,
                        interval: 0,
                    }
                },
                yAxis: [
                    {
                        type: "value",
                        inverse: false,
                        max: 100,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgba(117, 168, 202, 0.3)',
                                type: 'dashed'
                            }
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "#fff"
                            }
                        },
                        axisLabel: {
                            show: false,
                            formatter: "{value}",
                            textStyle: {
                                color: '#fff'
                            }
                        },
                        nameTextStyle: {
                            color: '#fff',
                            padding: [0, 0, 0, 15]
                        },
                        axisTick: {
                            show: false
                        }
                    },
                ],
                series: [
                    {
                        name: legendData[0],
                        type: 'bar',
                        barWidth: 20,
                        label: {
                            normal: {
                                show: true,
                                // offset:[0,-30],
                                position: 'top',
                                formatter: '{c}%',
                                fontSize: 11,
                                color: '#fff',
                            },
                        },
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: 'rgba(253, 224, 89, 1)'
                                }, {
                                    offset: 1,
                                    color: 'rgba(253, 224, 89,0)'
                                }])
                            }
                        },
                        data: seriesData
                    },
                ]
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //-----
        // 各项目参训率*8
        project_graphics(xAxisData, seriesData, basicsData) {
            this.$echarts.init(document.getElementById("date_study_graphic")).dispose();
            let that = this;
            let myChart = this.$echarts.init(
                document.getElementById("date_study_graphic")
            );
            myChart.setOption({
                backgroundColor: that.graphics_bg,
                dataZoom: [
                    {
                        type: 'slider',
                        startValue: 0,
                        endValue: 4,
                        // start: 0,
                        // end: 40,
                        height: 4,
                        bottom: '6px',
                        zoomLock: true,
                        fillerColor: "#62c6f8", // 滚动条颜色
                        showDetail: false, // 拖拽时是否展示滚动条两侧的文字
                        borderColor: '#4B9FF6'
                    },
                ],
                grid: {

                    left: '8%',
                    top: '36%',
                    right: '3%',
                    bottom: '35%',
                },
                xAxis: {
                    axisLabel: {
                        show: true,
                        color: '#fff',
                        rotate: 38,
                        formatter: function (value) {
                            if (value.length > 6) {
                                return `${value.slice(0, 6)}...`;
                            }
                            return value;
                        }
                    },
                    axisTick: {
                        show: false // 是否显示坐标轴轴线
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#233b61'
                        }
                    },

                    data: xAxisData
                },
                yAxis: {
                    show: false,
                },
                series: [
                    {
                        name: '上部圆',
                        type: 'pictorialBar',
                        silent: true,
                        symbolSize: [58, 58],
                        symbolOffset: [0, -58],
                        symbolPosition: 'end',
                        z: 12,
                        label: {
                            normal: {
                                show: true,
                                offset: [0, -35],
                                align: 'center',
                                formatter: ((value) => {
                                    return seriesData[value.dataIndex] + '%'
                                }),
                                fontSize: 13,
                                color: '#fff',
                            },
                        },
                        color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: 'rgba(100, 202, 248,1)'
                        }, {
                            offset: 0.2,
                            color: 'rgba(100, 202, 248,1)'
                        }, {
                            offset: 1,
                            color: 'rgba(45, 107, 243, 1)'
                        }]),
                        data: basicsData
                    },
                    {
                        name: "Sale",
                        type: "bar",
                        barWidth: '4',
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 1,
                                    color: 'rgba(45, 107, 243, 1)'
                                }, {
                                    offset: 0,
                                    color: 'rgba(100, 202, 248,1)'
                                }])
                            }
                        },
                        data: basicsData
                    }]
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        }
        //-----  
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        //api
        this.getInfoList();
        this.getprojectUserCount();
        this.getprojectUserName();
        this.getprojectStudyInfo();
        this.gettimeQuest();
        this.gettimeStudy();
        this.getparticipationRate();
        //图例
    },
}
</script>
<style scoped lang="less">
@import "./Less/schoolSpace.less";
</style>